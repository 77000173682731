// src/components/Product.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../customColors.css';
import { motion, AnimatePresence } from 'framer-motion';

const images = [
  { src: "/images/acraai-black1.png", width: 0.5 },
  { src: "/images/acraai-white1.png", width: 0.5 },
  { src: "/images/acraai-bothgrad.png", width: 0.266 },
];

function Services() {
  const [index, setIndex] = useState(0);
  const [imageWidth, setImageWidth] = useState(window.innerWidth * images[0].width);
  
  useEffect(() => {
     images.forEach((image) => {
        const img = new Image();
        img.src = image.src;
     });

    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setImageWidth(window.innerWidth * images[newIndex].width);
        return newIndex;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <section className="text-center py-5 bg-custom-dark">
        <div className="container">
          <h1 className="display-4"><strong>SafeHome by Acra AI</strong></h1>
          <p className="lead mb-4">Your Next-Generation Plug-and-Play Security for Smart Devices.</p>
          <p className="text-warning font-weight-bold">Exclusive Early Deals Available on Kickstarter – <a href="https://www.kickstarter.com/projects/774148773/806365345?ref=b0x3z6&token=0bb84f16" className="text-warning">Back Us Today!</a></p>
          <AnimatePresence mode="wait">
            <motion.img
              key={index}
              src={images[index].src}
              className="object-cover rounded-lg"
              style={{ width: imageWidth, height: 'auto' }}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
        </div>
      </section>

      {/* Problem Statement */}
      <section className="bg-custom-light py-5">
        <div className="container">
          <h2 className="text-center mb-4"><strong>Smart Homes Are Under Attack</strong></h2>
          <p className="text-center">Every day, the average home network experiences <strong>10 IoT cyberattacks</strong> (<a href="https://www.netgear.com/hub/network/2024-iot-threat-report/
" target="_blank" rel="noopener noreferrer" className="bg-custom-light bg-custom-label">Netgear 2024</a>). In 2023, <a href="https://www.zscaler.com/blogs/security-research/2023-threatlabz-report-indicates-400-growth-iot-malware-attacks/" target="_blank" rel="noopener noreferrer" className="bg-custom-light bg-custom-label">ZScaler</a> reported over <strong> 6,000 malware attacks per week</strong> targeting smart devices. Cameras, speakers, thermostats, and locks are all vulnerable, and the problem is only getting <strong>worse</strong>.</p>
        </div>
      </section>

      {/* How SafeHome Solves It */}
      <section className="bg-custom-dark py-5">
        <div className="container">
          <h2 className="text-center text-white mb-4"><strong>How SafeHome Protects You</strong></h2>
          <p className="text-center">SafeHome uses <strong> AI-driven threat detection</strong> to prevent cyberattacks before they happen, ensuring your network data stays <strong> private </strong> without relying on cloud storage.</p>
          <div className="text-center mt-4">
            <video width="60%" controls>
              <source src="/videos/acraai-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
         <p>When you connect your smart devices to the Safe Home, it automatically <strong>monitors</strong> the traffic of each device. Safe Home uses machine learning to create a <strong> fingerprint </strong> unique to your devices. If someone tries to compromise that device, Safe Home will be able to detect changes to that fingerprint. It will then notify the app in real time, letting the user know within <strong> seconds of an attack</strong>.</p>
        </div>
      </section>

      {/* Key Features */}
      <section className="bg-custom-light py-5">
        <div className="container">
          <h2 className="text-center mb-4"><strong>Key Features</strong></h2>
          <div className="row">
            <div className="col-md-4">
              <h5><strong>Intrusion Detection System (IDS)</strong></h5>
              <p>Real-time monitoring that detects anomalies and suspicious activity on your smart devices.</p>
            </div>
            <div className="col-md-4">
              <h5><strong>Plug-and-Play Security</strong></h5>
              <p>Acts as a secure Wireless Access Point (WAP) to instantly protect smart devices.</p>
            </div>
            <div className="col-md-4">
              <h5><strong>Peer-to-Peer (P2P) Security</strong></h5>
              <p>Provides encrypted remote access without relying on a central database.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Premium Features */}
      <section className="bg-custom-dark text-white py-5">
        <div className="container">
          <h2 className="text-center mb-4"><strong>Premium Features</strong></h2>
          <div className="row">
            <div className="col-md-4">
              <h5><strong>Intrusion Prevention System (IPS)</strong></h5>
              <p>Actively blocks cyber threats before they reach your smart devices.</p>
            </div>
            <div className="col-md-4">
              <h5><strong>Machine Learning Customizability</strong></h5>
              <p>Fine-tune AI-based security models to fit your specific needs.</p>
            </div>
            <div className="col-md-4">
              <h5><strong>Early Feature Access</strong></h5>
              <p>Be the first to test and deploy the latest security enhancements.</p>
            </div>
          </div>
          <p className="text-center mt-3">Available with <strong>Acra AI Premium ($99/year or $11.99/month).</strong></p>
        </div>
      </section>

      {/* App Snippets & Live Demos */}
      <section className="bg-custom-light py-5">
        <div className="container">
          <h2 className="text-center"><strong>App Snippets</strong></h2>
          <div className="row text-center">
            <div className="col-md-4">
              <p><strong>Smart Home Setup</strong></p>
              <video width="300" controls>
                <source src="/videos/AddDevice.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="col-md-4">
              <p><strong>Calibrating Smart Home to Your Network</strong></p>
              <video width="300" controls>
                <source src="/videos/ShowingNetworkStatus.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="col-md-4">
              <p><strong>Detecting Cyber Attacks</strong></p>
              <video width="300" controls>
                <source src="/videos/RecordedAnomalyDemo.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>

      {/* Kickstarter CTA */}
      <section className="bg-custom-dark text-center py-5">
        <h2 className="h3 text-white"><strong>Exclusive Kickstarter Pre-Launch Deals</strong></h2>
        <a href="https://www.kickstarter.com/projects/774148773/806365345?ref=b0x3z6&token=0bb84f16" className="btn btn-custom btn-lg">Check it Out!</a>

      </section>
    </div>
  );
}

export default Services;
