// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './components/About'; 
import LandingPage from './components/LandingPage';   
import Contact from './components/Contact';
import SafeHome from './components/Services';

function App() {
  return (
    <Router>
      <Navbar /> {/* Navbar will stay on all pages */}
      <Routes>
        <Route path="/" element={<SafeHome />} />    {/* Define the Home route */}
        <Route path="/about" element={<About />} />  {/* Define the About route */}
        <Route path="/contact" element={<Contact />} />  {/* Define the Contact route */}
        <Route path="/safehome" element={<SafeHome />} />  {/* Define the Services route */}
      </Routes>
    </Router>
  );
}

export default App;
