// src/components/About.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../customColors.css'
import headshot from '../content/Headshot.jpg';
function About() {
  return (
    <div className="">
      <header className="bg-custom-dark text-center py-5">
        <h1 className="display-4"><strong>About Us</strong></h1>
        <p className="lead">Building the Future of Smart Home Security—One Device at a Time.</p>
      </header>

  {/*Mission Section*/}
         <section className="bg-custom-light py-5">
           <div className="container">
              <div className="row">   
                {/* Left Column: Title/Heading */}
                <div className="col-md-4 d-flex align-items-center">
                  <h2>
                  <strong>
                    The Mission
                   </strong>
                  </h2>
                </div>
                  
                {/* Right Column: Text Block */}
                <div className="col-md-8">
                  <p className="lead">
                   The mission of Acra AI is simple, protect smart devices in peoples homes and businesses.
                   In order to achieve this, we have to tackle an almost decade long problem (Since the infamous
                   2016 Mirai Attacks causing billions of dollars of infrastructure damage around the world).
                   By adding state of the art security onto IoT devices and infrastructure,
                   we will protect preexisting and new smart home environments.
                   We hope through alleviating the fear of smart device infiltration, people that haven’t bought IoT devices for security reasons, can start.
                    
                  </p>
                </div>
              </div>
             </div>
        </section>
	{/*Vision Overview*/}
	 <section className="bg-custom-dark py-5">
	   <div className="container">
	      <div className="row">
	      
	      	{/* Left Column: Title/Heading */}
	        <div className="col-md-4 d-flex align-items-center">
	          <h2>
	          <strong>
	            Our Vision
	           </strong>
	          </h2>
	        </div>

	          {/* Right Column: Vertical List of Vision Statments */}
		<div className="col-md-8">
		  <ul className="list-unstyled">
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
		      Our vision is to be a leader in IoT cybersecurity, empowering smart device users with seamless and secure user experiences.
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
                      We aim to revolutionize the industry with cutting-edge machine learning technologies that proactively defend against evolving cyber threats on the device
                       itself. 
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
			Through partnership with innovative tech companies, we will set new standards in data privacy and security. 
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
			Our ultimate goal is to create a world where every connected device is protected, giving users peace of mind in an increasingly digital age.
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
		       By setting standards to prevent data from being centralized, We will protect not just your network but your data from being sold.
		    </li>
		  </ul>
		</div>
		

	      </div>
	     </div>
        </section>

      {/*About our Founder*/}
      <section className=" bg-custom-light">
      <div className="container py-5">
      <div className="row align-items-center">
        {/* Left Column: Blurb about Founder */}
        <div className="col-md-6">
          <h2><strong>About Our Founder</strong></h2>
          <p>
Steven Arroyo founded Acra AI in September 2023 while completing his thesis. His research in cybersecurity began in 2021 during his undergraduate studies when he developed an anomaly detection algorithm that excelled at detecting botnet attacks on IoT devices. Under the mentorship of Dr. Shen-Shyang Ho, he refined this work and published his findings at the IEEE Conference on Machine Learning Applications (ICMLA) 2022. Expanding on this foundation, he later developed a federated learning system to detect broader IoT cyber threats, leading to publications at the IEEE Conference on Fog and Edge Computing (ICFEC) 2024 and ScienceDirect’s Expert Systems and Applications (ESA).
          </p>
          <p>
While these academic contributions demonstrated the effectiveness of his algorithms, Acra AI’s breakthrough came through months of independent development beyond academia. Steven optimized the anomaly detection system to run efficiently on a microcontroller (1GHz CPU, 512MB RAM), drastically improving computational efficiency without altering the core machine learning model. This resulted in SafeHome, a lightweight, plug-and-play cybersecurity device that learns and protects a home’s IoT network in real time.
          </p>
          <p>
Beyond research, Steven has nearly a decade of software engineering experience, including developing software with machine learning systems for Senior Leadership and working on real-time ballistic missile defense command and control systems to ensure reliability and efficiency.
</p>
<p>
SafeHome is just the beginning—Acra AI is building the future of consumer IoT security.
          </p>
        </div>

        {/* Right Column: Image of Founder */}
        <div className="col-md-6 text-center">
 	<img
	     src={headshot}
             className="img-fluid mb-3"
	     alt="Headshot"
             style={{ width: '450px', height: 'auto' }}
         />
        </div>
      </div>
      </div>
    </section>
      {/* Call to Action */}
      <section className="bg-custom-dark text-center py-5">
        <h4><strong>Want to collaborate?</strong></h4>
        <a href="/contact" className="btn btn-custom btn-lg">Contact Us</a>
      </section>
      
    </div>
  );
}

export default About;
