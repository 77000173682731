// src/components/LandingPage.js https://www.zscaler.com/blogs/security-research/2023-threatlabz-report-indicates-400-growth-iot-malware-attacks https://www.cloudi-fi.com/blog/how-can-iot-network-segmentation-help-set-boundaries-for-a-secure-iot-framework


//Content Imports
import boardComparison from '../content/BoardComparison.jpg';
import fourStat from '../content/Alarm.png';
import twohundredStat from '../content/IoT.png';
import firewall from '../content/Firewall.png';


//Slideshow imports
//import safeHomeImg1 from '../content/acraai-black1.png';
//import safeHomeImg2 from '../content/acraai-white1.png';
//import safeHomeImg3 from '../content/acraai-bothgrad.png';


//
import React from 'react';
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../customColors.css';



const images = [
  { src: "/images/acraai-black1.png", width: .5 },
  { src: "/images/acraai-white1.png", width: .5 },
  { src: "/images/acraai-bothgrad.png", width: .266 },
];

const preloadImages = (imageArray) => {
	imageArray.forEach((imgData)=> {
		const img = new Image();
		img.src = imgData.src;

	});
}

function LandingPage() {
 
  const [index, setIndex] = useState(0);
  //const [imageWidth, setImageWidth] = useState(images[0].width);
  const [imageWidth, setImageWidth] = useState(window.innerWidth * images[0].width);
  const [loaded,setLoaded] = useState(false);
  useEffect(()=>{
	preloadImages(images);
	setLoaded(true);

  },[]);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % images.length;
        setImageWidth(window.innerWidth*images[newIndex].width);
        return newIndex;
      });
    }, 5000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, []);
 
 if(!loaded) return <p>Loading...</p>;

  return (
    <div>

      {/* Hero Section */}
      <section className="bg-custom-dark text-center py-5">
        <div className="container">
          <h1 className="display-3"><strong>Welcome to Acra AI</strong></h1>
          <p className="lead mb-4"> The Future of Smart Device Security</p>

        {/*Slide Show*/}
              <AnimatePresence mode="wait">
	        <motion.img
	          key={index}
	          src={images[index].src}
                  className="object-cover rounded-lg" 
                  style={{width: imageWidth, height:'auto'}}
	          initial={{ opacity: 0, x: 50 }}
	          animate={{ opacity: 1, x: 0 }}
	          exit={{ opacity: 0, x: -50 }}
	          transition={{ duration: 0.5 }}
	        />
	      </AnimatePresence>
          {/*<a href="/contact" className="btn btn-primary btn-lg">Get Started</a>*/}
        </div>
      </section>
      {/*About Section*/}
	 <section className="bg-custom-light py-5">
	   <div className="container">
	      <div className="row">
	        {/* Left Column: Title/Heading */}
	        <div className="col-md-4 d-flex align-items-center">
	          <h2>
	          <strong>
	            About Us
	           </strong>
	          </h2>
	        </div>

	        {/* Right Column: Text Block */}
	        <div className="col-md-8">
	          <p className="lead">
	           The mission of Acra AI is simple, protect smart devices in peoples homes and businesses. 
	           In order to achieve this, we have to tackle an almost decade long problem (Since the infamous
		   2016 Mirai Attacks causing billions of dollars of infrastructure damage around the world). 
		   By adding state of the art security onto IoT devices and infrastructure, 
		   we will protect preexisting and new smart home environments. 
		   We hope through alleviating the fear of smart device infiltration, people that haven’t bought IoT devices for security reasons, can start.

	          </p>
	        </div>
	      </div>
	     </div>
        </section>
      {/*List of Stats*/}
      <section className="bg-custom-dark">
      <div className = "container py-5">
      {/* Header */}
      <h2 className="text-center mb-4"><strong>Here are the Facts</strong></h2>

      {/* Horizontal List */}
      <div className="bg-custom-dark row text-center">
        {/* First Block */}
        <div className="col-md-4">
          <img
            src={twohundredStat}
            className="img-fluid mb-3"
            alt="Feature 1"
            style={{ width: '120px', height: 'auto' }}
          />
          <p> <a href="https://www.forbes.com/sites/bernardmarr/2023/10/19/2024-iot-and-smart-device-trends-what-you-need-to-know-for-the-future/" target="_blank" rel="noopener noreferrer" className="bg-custom-dark bg-custom-label">Forbes</a> is projecting a total of <strong style={{'font-size': '20px'}}>201 billion </strong> IoT devices in the market by the end of this year.</p>
        </div>

        {/* Second Block */}
        <div className="col-md-4">
          <img
            src={fourStat}
            className="img-fluid mb-3"
            alt="Feature 2"
            style={{ width: '120px', height: 'auto' }}
          />
          <p>  <a href="https://www.zscaler.com/blogs/security-research/2023-threatlabz-report-indicates-400-growth-iot-malware-attacks/" target="_blank" rel="noopener noreferrer" className="bg-custom-dark bg-custom-label">ZScaler</a> reports IoT attacks are increasing
at <strong style={{'font-size': '20px'}}>400%</strong> each year (6,000 attacks per week in 2023).</p>
        </div>

        {/* Third Block */}
        <div className="col-md-4">
          <img
            src={firewall}
            className="img-fluid mb-3"
            alt="Feature 3"
            style={{ width: '125px', height: 'auto' }}
          />
          <p> The current standard is to isolate IoT devices on their own  <a href=" https://www.cloudi-fi.com/blog/how-can-iot-network-segmentation-help-set-boundaries-for-a-secure-iot-framework" target="_blank" rel="noopener noreferrer" className="bg-custom-dark bg-custom-label">network</a>, leaving them <strong style={{'font-size': '20px'}}> vulnerable to attack.</strong></p>
        </div>
        
      </div>
     </div>
    </section>
    
      {/*How Section*/}
	 <section className="bg-custom-light py-5">
	   <div className="container">
	      <div className="row">
	        {/* Left Column: Title/Heading */}
	        <div className="col-md-4 d-flex align-items-center">
	          <h2 className="font-weight-bold">
	          <strong>
	            How We Solve This Problem
	          </strong>
	          </h2>
	        </div>

	        {/* Right Column: Text Block */}
	        <div className="col-md-8">
	          <p className = "lead bg-custom-light">
	            We have spent the last 3 years studying and applying the latest research on
		protecting these devices. We did this by reviewing and creating state of the
		art machine learning based intrusion detection and prevention systems. More
		importantly, our research also consisted of making these algorithms efficient
		enough to operate on a micro-controller one half the size of a credit card.
	          </p>
	        </div>
	      </div>
	     </div>
        </section>
        
       {/*Product Overview*/}
	 <section className="bg-custom-dark py-5">
	   <div className="container">
	      <div className="row">
	      
	      <h2 className="font-weight-bold text-center">
	            <strong>Our Product</strong>
	      </h2>

	          {/* Left Column: Vertical List of Features */}
		<div className="col-md-7">
		  <ul className="list-unstyled align-items-center">
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
		      Wireless Access Point (WAP) to connect your smart devices too. 
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
		      Seamless Plug-in-Play App for easy setup (like any smart device)
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
			Pretrained on-device model that detects cyberthreats.
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
			2 Methods to Fine Tune the model to your networks digital fingerprint. 
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
		      Peer-To-Peer (P2P) connection between WAP and app to ensure Data Privacy. 
		    </li>
		  </ul>
		</div>
		
	        {/* Right Column: MicroController Pic */}
	        <div className="col-md-5 d-flex align-items-center">
	          {/*Board Image*/}
			 <img
			    src={boardComparison}
			    className="img-fluid"
			    alt="board image"
			    style={{ width: window.innerWidth*.9, height: 'auto' }}
			  />
                 

	        </div>
               
	      </div>
	     </div>
        </section>
        
       {/* Call to Action Section */}
      <section className="bg-custom-light">
      <div className = "text-center py-5">
        <h2 className="h3"><strong>Want to Invest?</strong></h2>
        <p className="lead">We need investors to bring our products to market! </p>
        <a href="/contact" className="btn btn-custom btn-lg">Contact Us</a>
      </div>
      </section>

    </div>
  );
}

export default LandingPage;

